<template>
  <div>
  </div>
</template>

<script>
import { returnUrlNotifyAPP } from "../../api/modules/payTool";
import  uni  from "@/utils/uni-webview.js";
var timer=null
export default {
  data() {
    return {

    };
  },
  mounted() {
    this.$nextTick(() => {
      this.reportPaymentResults();
    });
  },
  methods: {
    reportPaymentResults() {
      let loading=this.$loading({
          lock: true,
          text: 'Loading', 
      });
      let platform = this.judgmentPlatform()
      if (platform == 'phone') {
        
        timer= setTimeout(() => {
          loading.close();
          uni.navigateBack({
            delta: 1
          });
        }, 3000);
        returnUrlNotifyAPP(this.$route.query||{}).then(r => {
          clearTimeout(timer)
          uni.navigateBack({
            delta: 1
          });
          console.log('r', JSON.stringify(r))
        }).catch(err => {
          // this.$message({
          //     type: 'error',
          //     message: JSON.stringify(err)
          //   });
          console.log('err', JSON.stringify(err))
        })
      } else {
        this.$router.push("/home");
      }
    },
    judgmentPlatform() {
      console.log('navigator', navigator)
      var phone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (phone) {
        return 'phone'
      } else {
        return 'pc'
      }
    },
  }
};
</script>
<style lang="less" scope></style>
